import React, { useState, Fragment } from 'react';
import { FormattedMessage } from 'gatsby-plugin-react-intl';
import { Link } from 'gatsby-plugin-react-intl';
import { Form, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import NotFoundPage from '../../pages/404';
import queryString from 'query-string';
import { DANCE_AREA } from '@/config/constants';
import { CHECKOUT_INIT } from '../../webapi/WebApi';
import client from '@/webapi/client';

// switch for serverside (gatsby) build
const isGatsbyBuild = typeof window === 'undefined';
const ORIGIN = isGatsbyBuild ? '' : window.location.origin;

const Invite = () => {
  const query = isGatsbyBuild ? {} : queryString.parse(window.location.search);
  const initialCoupon = query.c ?? query.coupon;

  const { handleSubmit, register } = useForm({
    defaultValues: {
      coupon: initialCoupon,
    },
  });
  const [checkoutId, setCheckoutId] = useState(null);

  const onSubmit = async (formValues) => {
    const checkoutInitInput = {
      inviteCode: process.env.GATSBY_DANCE_INTERNAL_INVITE_CODE,
      email: formValues.email,
      payload: JSON.stringify({
        firstname: formValues.firstname,
        lastname: formValues.lastname,
        postcode: formValues.postcode,
        city: formValues.city,
      }),
      coupon: formValues.coupon,
      danceArea: formValues.danceArea ?? 'BERLIN',
    };

    const res = await client.mutate({
      mutation: CHECKOUT_INIT,
      variables: {
        checkoutInitInput,
      },
    });
    const record = res.data.checkoutInit;

    const checkoutId = record.id;
    setCheckoutId(checkoutId);
  };

  if (checkoutId) {
    const redirectUrl = `/c?cid=${checkoutId}`;

    return (
      <Fragment>
        <h2>
          <FormattedMessage id="invite.title" defaultMessage="Email" />
        </h2>
        <div>
          <FormattedMessage
            id="invite.summary"
            defaultMessage="
              Hey you!
              {br}
              {br}
              You are one of the first dancers all around. You can join our
              community of stress-free-ebike dancers!
              {br}
              Supercharge your commute. Never run out of power again.
              {br}
              {br}
              Click here to join: {joinLink}
              {br}
              {br}
              Greets, the Dancers
          "
            values={{
              br: <br />,
              joinLink: (
                <Link data-test-id="waitlist-invitelink" to={redirectUrl}>
                  {ORIGIN}
                  {redirectUrl}
                </Link>
              ),
            }}
          />
        </div>
      </Fragment>
    );
  } else if (process.env.GATSBY_ENVIRONMENT === 'production') {
    return <NotFoundPage withLayout={false} />;
  } else {
    return (
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Label>
          <b>Invite to Dance!</b>
          <br />
          <br />
          This is just a <b>development page</b> for quick checkout
          initialization.
          <br />
        </Form.Label>
        <select name="danceArea" {...register('danceArea')}>
          {Object.keys(DANCE_AREA).map((area) => (
            <option key={area} value={area}>
              {area}
            </option>
          ))}
        </select>{' '}
        <input
          name="coupon"
          placeholder="coupon (optional)"
          type="text"
          {...register('coupon')}
        />
        <br />
        <br />
        <Button
          variant="primary"
          type="submit"
          block
          data-test-id="submit-waitlist"
        >
          Generate Invite
        </Button>
      </Form>
    );
  }
};

export default Invite;
